<div class="celum-table-header_count">
  <ng-content data-test-celum-table-header-count select="table-header-counter"></ng-content>
</div>

<ng-container>
  <ng-content class="celum-table-header_filter-chips" data-test-celum-table-header-filter-chips select="table-header-filter-chips"></ng-content>
</ng-container>

<div class="celum-table-header_sort-and-filter">
  <ng-container>
    <ng-content data-test-celum-table-header-sorting select="table-header-sorting"></ng-content>
  </ng-container>
  <ng-container>
    <ng-content data-test-celum-table-header-advanced-filter select="table-header-filter"></ng-content>
  </ng-container>
</div>
