export function getFilenameExtension(fileName: string): string {
  if (!fileName) {
    return fileName;
  }
  const fileNameSplit = fileName.split('.');
  return fileNameSplit.length > 1 ? fileNameSplit.reverse()[0] : undefined;
}

export function getLowerCaseFilenameExtension(fileName: string): string {
  const extension = getFilenameExtension(fileName);

  return extension?.toLowerCase() ?? extension;
}
