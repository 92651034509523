import { ChangeDetectionStrategy, Component } from '@angular/core';

import { AuthService } from '@celum/authentication';
import { LOCAL_STORAGE_LIBRARIES_FILTER_KEY } from '@celum/libraries/api';
import { RouteParamService } from '@celum/ng2base';
import { SystembarConfiguration } from '@celum/shared/ui';
import { LanguageInitializationService, removeCelumLoadingOverlay, UserPilot, UserPilotInfo, WIZARD_URL_PARAM } from '@celum/shared/util';

@Component({
  selector: 'libs-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class AppComponent {
  protected systembarConfiguration: SystembarConfiguration = {
    onTenantSwitch: this.removeFilterFromLocalStorage
  };

  constructor(
    private languageInitializationService: LanguageInitializationService,
    private routeParamService: RouteParamService,
    private userPilot: UserPilot<UserPilotInfo>,
    private authService: AuthService
  ) {
    this.languageInitializationService.init();
    this.routeParamService.clearParams([WIZARD_URL_PARAM]);
    this.userPilot.initializeIfConfigured();
    this.authService.onSignOut = this.removeFilterFromLocalStorage;
  }

  protected removeLoadingOverlay(): void {
    removeCelumLoadingOverlay();
  }

  private removeFilterFromLocalStorage(): void {
    localStorage.removeItem(LOCAL_STORAGE_LIBRARIES_FILTER_KEY);
  }
}
