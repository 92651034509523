<div class="celum-table-count">
  @if (!countAsParameter) {
    <span class="count">{{ count() }}&nbsp;</span>
  }
  <span
    class="celum-table-count_label"
    [innerHTML]="(count() === 1 ? countLabelSingular() : countLabelPlural() || countLabelSingular()) | translate: { count: count() } | sanitized"></span>
  @if (showLink()) {
    <span
      class="celum-table-count_link"
      [innerHTML]="(linkCount() === 1 ? linkLabelSingular() : linkLabelPlural() || linkLabelSingular()) | translate: { linkCount: linkCount() } | sanitized"
      (click)="linkClicked.emit()"></span>
  }
</div>
