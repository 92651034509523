import { AssetFilterDto, AssetMetadataFieldDto, LibraryStatus } from '@celum/libraries/domain';
import { PaginationOption } from '@celum/shared/domain';

export interface LibraryFindOptions extends PaginationOption {
  /** Filter for libraries which names are starting with the passed value. */
  nameStartsWith?: string;
  /** Filter for libraries which names contain the passed value (case-insensitive). */
  nameContains?: string;
  page?: number;
  idIn?: string[];
  statusIn?: LibraryStatus[];
  hasSyncError?: boolean;
  size?: number;
  createdBy?: string;
  sort?: string[];
}

export interface LibraryFindOneOptions {
  id?: string;
}

export enum LibraryCreationRequestType {
  CONNECTED = 'connected',
  SYSTEM = 'system'
}

export enum UpdateRequestType {
  status = 'status',
  name = 'name',
  allMutable = 'allMutable'
}

export interface LibraryCreator extends LibraryUpdater {
  assetRepositoryId: string;
  creationRequestType: LibraryCreationRequestType;
}

export interface LibraryEditor extends LibraryUpdater {
  updateRequestType: UpdateRequestType;
}

export interface LibraryUpdater {
  name: string;
  downSyncFilter: AssetFilterDto;
  infoFieldMapping: AssetMetadataFieldDto[];
  variantTypes: string[];
}

export interface LibraryStatusUpdater {
  status: LibraryStatus;
  updateRequestType: UpdateRequestType;
}
