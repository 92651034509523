import { ChangeDetectionStrategy, Component, HostBinding, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'celum-table-header',
  styleUrl: 'table-header.component.scss',
  templateUrl: 'table-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  // Needed to style the dynamically inserted components
  encapsulation: ViewEncapsulation.None
})
export class CelumTableHeaderComponent {
  @HostBinding('class.celum-table-header') public hostCls = true;
}
