import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  imports: [CommonModule, MatMenuModule, MatDialogModule, MatTooltipModule, TranslateModule],
  selector: 'clickable-popup-dialog',
  templateUrl: './clickable-popup-dialog.component.html',
  styleUrls: ['./clickable-popup-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClickablePopupDialogComponent {
  @Input() public triggerClass: string;
  @Input() public contentClass: string;
  @Input() public triggerTooltipTranslationKey: string;
  @Input() public enableHoverColorOnOpenedMenu = true;
  @Output() public readonly menuOpenedChanged = new EventEmitter<boolean>();

  @ViewChild(MatMenuTrigger) protected readonly matMenuTrigger: MatMenuTrigger;

  protected isMenuOpen = false;

  @HostListener('click', ['$event'])
  protected onClick(event: MouseEvent): void {
    event.stopPropagation();
  }

  protected menuOpened(): void {
    this.isMenuOpen = true;
    this.menuOpenedChanged.emit(true);
  }

  protected menuClosed(): void {
    this.isMenuOpen = false;
    this.menuOpenedChanged.emit(false);
  }

  public openOrCloseMenu(open: boolean): void {
    if (open) {
      this.matMenuTrigger.openMenu();
    } else {
      this.matMenuTrigger.closeMenu();
    }
  }
}
