import { Component, EventEmitter, input, Input, Output, ViewEncapsulation } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { CelumAvatarModule } from '@celum/common-components';
import { CelumPipesModule } from '@celum/ng2base';

/**
 * Component to display a count of items in a table. Can optionally also display a link that triggers an event.
 */
@Component({
  selector: 'celum-table-count',
  templateUrl: './table-count.component.html',
  styleUrls: ['./table-count.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [StoreModule, CelumAvatarModule, CelumPipesModule]
})
export class CelumTableCountComponent {
  /**
   * Whether the count should be part of the text. If yes, the text needs to contain a placeholder for {{count}}
   */
  @Input()
  public countAsParameter = true;

  /**
   * Text to display when there is exactly one item
   */
  public countLabelSingular = input<string>();

  /**
   * Text to display when there are multiple or zero items
   */
  public countLabelPlural = input<string>();

  /**
   * The count of items
   */
  public count = input<number>();

  /**
   * Whether to show a link that triggers the linkClicked event
   */
  public showLink = input<boolean>(false);

  /**
   * Text to display for the link when there is exactly one item. Translations must contain {{linkCount}} as a placeholder for the count
   */
  public linkLabelSingular = input<string>();

  /**
   * Text to display for the link when there are multiple or zero items. Translations must contain {{linkCount}} as a placeholder for the count
   */
  public linkLabelPlural = input<string>();

  /**
   * The count of items for the link
   */
  public linkCount = input<number>();

  @Output() public readonly linkClicked = new EventEmitter<void>();
}
